@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 100px 0;

    @include mq.mq($from: large) {
        flex-direction: row;
    }
}

.imageWrapper {
    flex-basis: 50%;
    width: 100%;

    @include mq.mq($from: large) {
        width: 50%;
        height: 100%;
    }
}

.image {
    width: 100%;
    height: 100%;
}

.selectionWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    flex: 1 1 50%;
    margin-top: 25px;

    @include mq.mq($from: large) {
        width: 50%;
        padding-left: 60px;
        margin-top: 0;
    }
}

.selectionButtonsWrapper {
    margin-top: var(--spacing-core-6);
    gap: var(--spacing-core-2);
    display: flex;
    flex-direction: column;
    min-width: 50%;
    width: 100%;

    @include mq.mq($from: 1280px) {
        margin-top: var(--spacing-core-8);
    }
}

.logInButtons {
    flex-direction: column;
    display: flex;
    gap: var(--spacing-core-2);

    @include mq.mq($from: medium) {
        flex-direction: row;
    }
}

.logInButton {
    display: flex;
    width: 100%;

    @include mq.mq($from: medium) {
        width: 216px;
    }
}

.chipImage {
    width: var(--sizing-core-8);
    height: var(--sizing-core-8);
}

.imageSet {
    width: 100%;
    height: 100%;
}

.preferences {
    flex-direction: column;

    > * {
        max-width: 343px;
    }
}
