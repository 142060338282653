.grid {
    background-color: var(--color-background-primary-default);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--spacing-core-1);

    @container (width < 100px) {
        grid-gap: 2px;
    }
}

.column {
    grid-column: span 2;
}

.image {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
