
@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    position: relative;
    padding-top: var(--spacing-core-6);
    padding-bottom: var(--spacing-core-6);
}

.titleRow {
    position: relative;
    justify-content: space-between;
    display: flex;
}

.carouselRow {
    position: relative;
}

.seeMoreText {
    text-align: right;
}

.buttonLeft,
.buttonRight {
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 64px;
    height: 64px;
    box-shadow: 0 0 1px rgb(0 0 0 / 15%), 0 0 25px rgb(0 0 0 / 15%);
    transform: scale3d(0.9, 0.9, 0.9);

    svg {
        height: 24px;
        width: 20px;
    }

    &:hover {
        transform: scale3d(1, 1, 1);
        box-shadow: 0 0 6px rgba(0 0 0 / 15%),0 0 50px rgb(0 0 0 / 15%);
        background-color: var(--color-background-secondary-default);
    }

    @media (pointer: coarse) {
        display: none;
    }
}

.buttonLeft {
    left: 0;
    margin-left: -32px;
    margin-top: calc(var(--spacing-core-18) * -1);
}

.buttonRight {
    right: 0;
    margin-right: -32px;
    margin-top: calc(var(--spacing-core-18) * -1);
}

.carousel {
    --carousel-items: 1.5;
    --carousel-snap-align: center;
    --carousel-gap: var(--spacing-core-4);

    margin-left: calc(var(--spacing-core-4) * -1);
    margin-right: calc(var(--spacing-core-4) * -1);
    scroll-padding: calc(var(--spacing-core-4) * -1);
    scrollbar-width: none;

    & > *:first-child {
        box-sizing: content-box;
        padding-left: calc(var(--spacing-core-4) * 1);
    }

    & > *:last-child {
        box-sizing: content-box;
        padding-right: calc(var(--spacing-core-4) * 1);
    }

    @include mq.mq($from: small) {
        --carousel-items: 1.75;
    }

    @include mq.mq($from: small, $until: medium) {
        margin-left: calc(var(--spacing-core-8) * -1);
        margin-right: calc(var(--spacing-core-8) * -1);
        scroll-padding: calc(var(--spacing-core-8) * -1);

        & > *:first-child {
            padding-left: calc(var(--spacing-core-8) * 1);
        }

        & > *:last-child {
            padding-right: calc(var(--spacing-core-8) * 1);
        }
    }

    @include mq.mq($from: medium) {
        --carousel-items: 3.5;
    }

    @include mq.mq($from: large) {
        --carousel-snap-align: start;
        --carousel-items: 5;
        --carousel-gap: var(--spacing-core-6);
    }

    @include mq.mq($from: extra-large) {
        --carousel-items: 6;
    }
}
