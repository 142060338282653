@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

$ac-destruction: #ff1f1f;

dialog[open].modal {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    border-radius: 0;

    @include mq.mq($until: small) {
        height: 100%;
    }
    
    @include mq.mq($from: medium) {
        max-width: 496px;
        border-radius: var(--border-radius-core-l);
    }
}

.modalHeader {
    padding: var(--spacing-core-6);
}

.modalContent  {
    flex-grow: 1;
    padding: 0 var(--spacing-core-6);
}

.modalFooter {
    padding: var(--spacing-core-6);
    display: flex;
    flex-direction: column;
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-core-2);
}

.button {
    margin: 0;
    width: 100%;
}

.errorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-core-4) var(--spacing-core-6);
    margin: 0 calc(var(--spacing-core-6) * -1);
    gap: var(--spacing-core-4);
    background: #f3f6f8
}

.errorIcon {
    fill: $ac-destruction;
}

.errorMessage {
    font-weight: normal;
}
