.root {
    margin-top: var(--spacing-core-4);
    text-align: center;
}

.separator {
    width: 2px;
    height: 20px;
    background: var(--color-action-primary-default);
    margin: var(--spacing-core-1) auto;
}
