@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.image {
    display: block;
    height: 100%;
    width: 100%;
}

@include mq.mq($from: large) {
    .image {
        max-height: 420px;
        overflow: hidden;
    }
}

.textWrapper {
    padding: var(--spacing-core-4) var(--spacing-core-4)
        var(--spacing-core-8);

    li {
        margin: var(--spacing-core-2) 0;
    }

    ol {
        counter-reset: number;
        list-style-type: none;

        li {
            position: relative;

            &::before {
                position: absolute;
                top: 1px;
                left: calc(var(--spacing-core-6) * -1);
                counter-increment: number;
                content: counter(number) '\a0';
                font-weight: bold;
            }
        }
    }
}

.heading {
    margin: var(--spacing-core-2) 0;
}

@include mq.mq($from: medium) {
    .textWrapper {
        padding: var(--spacing-core-4) var(--spacing-core-6)
            var(--spacing-core-8);
    }
}

@include mq.mq($from: large) {
    .heading {
        margin-top: calc(var(--spacing-core-3) * -1);
        margin-bottom: var(--spacing-core-4);
    }

    .textWrapper {
        padding-top: 0;
    }

    .textWrapperRight {
        padding-left: var(--spacing-core-16);
        padding-right: 0;
    }

    .textWrapperLeft {
        padding-right: var(--spacing-core-16);
        padding-left: 0;
    }
}
