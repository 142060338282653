$border-radius: 16px;
$link-transition: 250ms ease-out;

.root {
    /* stylelint-disable-next-line custom-property-pattern */
    font-family: var(--typography-body-regular-desktop-fontFamily);
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #FFF;
    border-radius: $border-radius;
    box-shadow: 0 2px 8px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 8%)
}

.imageWrapper {
    height: 176px;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    cursor: pointer;
    overflow: hidden;
    flex: 0 0 auto;

    &:hover,
    &:focus {
        .figure {
            transform: scale(1.02) translate3d(0, 0, 0);
        }
    }

    &:active {
        .figure {
            transform: scale(1) translate3d(0, 0, 0);
        }
    }
}

.figure {
    height: 100%;
    transition: transform $link-transition;
    transform-origin: center;
    transform: scale(1) translate3d(0, 0, 0);
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-core-8) var(--spacing-core-6);
}

.linkList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.linkContainer {
    display: flex;
    align-items: center;
    line-height: 24px;
}

.link {
    transition: transform 200ms ease-out;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
        transform: translateX(2px);
    }

    &:active {
        transform: translateX(0);
    }
}

.linkIcon {
    flex: 0 0 auto;
    margin-right: var(--spacing-core-2);
}

.heading {
    transition: opacity $link-transition;
    margin-bottom: var(--spacing-core-2);

    &:hover,
    &:focus {
        opacity: 0.75;
    }

    &:active {
        opacity: 0.6;
    }
}
