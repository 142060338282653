@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    max-width: 496px;
    width: 100%;
    padding: var(--spacing-core-4) 0;
    
    &::backdrop {
        background-color: rgb(48 48 48 / 60%);
    }

    @include mq.mq($until: small) {
        max-width: calc(100vw - var(--spacing-core-8));        
    }
}

.content {
    padding: 0 var(--spacing-core-4) var(--spacing-core-4) var(--spacing-core-4);
}

.header {
    padding: var(--spacing-core-4) var(--spacing-core-4) var(--spacing-core-4);
    min-height: var(--spacing-core-8);
}

.icon {
    width: var(--spacing-core-8);
    height: var(--spacing-core-8);
}

.footer {
    padding: var(--spacing-core-2) var(--spacing-core-4);
    display: flex;
    justify-content: center;
    white-space: nowrap;
    width: 100%;
}
