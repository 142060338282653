.root {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    font-weight: 800;
    margin-top: var(--spacing-core-2);
    transition: opacity 250ms ease-out;

    &:hover,
    &:focus {
        opacity: 0.75;
    }

    &:active {
        opacity: 0.6;
    }
}
