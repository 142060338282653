.video {
    display: flex;
    position: relative;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 20%);

    svg {
        position: absolute;
    }
}
