.counter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-core-2) var(--spacing-core-4);
    background-color: var(--color-background-primary-disabled);
    border-radius: var(--border-radius-core-m);
    max-width: 343px;
}

.button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    padding: var(--spacing-core-1);

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        svg {
            opacity: 0.33;
        }

        &:hover {
            cursor: not-allowed;
        }
    }
}
