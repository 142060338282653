.cta {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-foreground-inverted-default);
    border: none;
    width: var(--sizing-core-12);
    height: var(--sizing-core-12);
    user-select: none;
    background: none;

    &:hover,
    &:focus-visible {
        cursor: pointer;
    }

    svg {
        width: var(--sizing-core-6);
        height: var(--sizing-core-6);
    }

    &::before {
        background: radial-gradient(65% 65% at 100% 0,#000 0,transparent 100%);
        border-radius: var(--sizing-core-2);
        overflow: hidden;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        opacity: .25;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.selected  {
    color: #ea3d3d;
}

.top {
    &-left {
        top: 0;
        left: 0;
    }

    &-right {
        top: 0;
        right: 0;
    }
}
