.root {
    position: relative;
    width: 100%;
    border-radius: var(--border-radius-core-m);
    height: 144px;

    @container (width > 372px) and (width < 885px) {
        height: 168px;
    }

    @container (width >= 885px) {
        height: 184px;
    }
}