@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.title {
    position: relative;
    max-width: 708px;
    justify-content: flex-start;
    align-items: center;
    min-height: calc(var(--spacing-core-6) * 2);
    margin-top: var(--spacing-core-6);
    display: flex;

    & > h1 {
        overflow-wrap: break-word;
        min-width: 0;
    }
}

.titlePlaceholder {
    height: 100%;
}

@include mq.mq($from: medium) {
    .titlePlaceholder {
        height: var(--spacing-core-10);
    }
}
