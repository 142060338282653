.properties {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0 var(--spacing-core-2);
    max-height: var(--sizing-core-10);
    overflow: hidden;
    flex-shrink: 0;
    padding: 0;

    @container (width > 300px) {
        gap: 0 var(--spacing-core-5);
    }
}

.property {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: var(--sizing-core-10);
    font-size: 13px;
    gap: var(--sizing-core-1);
    /* stylelint-disable-next-line custom-property-pattern */
    font-family: var(--typography-subtext-regular-desktop-fontFamily);

    svg {
        width: var(--sizing-core-4);
        height: var(--sizing-core-4);
    }
}
