@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: var(--spacing-core-6);
    gap: 10px;

    @include mq.mq($from: medium) {
        align-items: center;
    }
}

.subtitleWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 10px;
    width: 100%;

    @include mq.mq($from: medium) {
        justify-content: center;
    }
}

.icons {
    display: inline-flex;
    vertical-align: middle;
    margin: 0 10px;
}

.preferencesLink {
    color: var(--color-foreground-primary-default);
    text-decoration: underline;
}

.carousel {
    gap: var(--spacing-core-4);
    scroll-padding-left: var(--spacing-core-4);

    &::-webkit-scrollbar {
        display: none;
    }
}

.carouselItem {
    width: 200px;

    @include mq.mq($from: medium) {
        width: 240px;
    }

    &:first-child {
        margin-left: var(--spacing-core-4);
    }

    &:last-child {
        margin-right: var(--spacing-core-4);
    }
}

.weekmenuWrapper {
    width: 100%;
    margin: 100px 0;
}

.desktopWeekmenuWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px 15px;

    & > [class*='card_root']:first-of-type {
        grid-row: 1 / span 2;
        display: flex;
        justify-content: space-between;

        div:has(img) {
            aspect-ratio: auto;
            height: 100%;
        }
    }
}
