@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: var(--spacing-core-3);
    margin-top: var(--spacing-core-6);
}

.chipsWrapper {
    margin-bottom: var(--spacing-core-3);
}

.chipsWrapperVertical {
    flex-flow: column nowrap;
    max-width: 343px;

    
    @include mq.mq($until: medium) {
        max-width: 100%;
    }
    
}

.chipsWrapperHorizontal {
    flex-flow: row nowrap;

    // Slightly lower than mobile viewport
    @include mq.mq($until: 355px) {
        flex-flow: column nowrap;
    }

    @include mq.mq($from: large) {
        max-width: 343px;
    }
}

.dietInfoText {
    color: var(--color-foreground-secondary-default);
}

.nextStepButton {
    margin-top: var(--spacing-core-5);
    width: auto;

    @include mq.mq($from: medium) {
        width: fit-content;
        min-width: 168px;
    }
}

.chipImage {
    width: var(--sizing-core-8);
    height: var(--sizing-core-8);
}

.errorMessage {
    color: var(--color-feedback-error-default);
}
