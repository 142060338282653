%base {
    aspect-ratio: 21 / 10;
    width: 100%;
    height: 100%;
}

.imageWrapper {
    @extend %base;

    overflow: hidden;
}

.placeholder {
    @extend %base;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;

    svg {
        height: var(--sizing-core-16);
        width: var(--sizing-core-16);
        color: #f7f7f7;
    }
}
