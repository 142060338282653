.imageWrapper {
    border-radius: var(--sizing-core-2);
    aspect-ratio: 16 / 10;
    overflow: hidden;
}

.image {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    transition: transform .2s cubic-bezier(.17,.67,.83,.67);
}

.empty {
    aspect-ratio: 21 / 10;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;

    svg {
        height: var(--sizing-core-16);
        width: var(--sizing-core-16);
        color: #f7f7f7;
    }
}
