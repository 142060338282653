.ctaWrapper {
    display: flex;
    min-width: 0;
    flex-shrink: 0;
}

.cta {
    border-radius: var(--sizing-core-5);
    padding: var(--spacing-core-2);
    height: auto;
    min-height: 0;
    min-width: 0;

    > * {
        font-size: 14px;
        font-weight: 800;
    }
}
