.root {
    position: relative;
    max-width: 1488px;
    margin: 0 auto;
    padding: 0;
}

.sectionGrid {
    width: 100%;
}
