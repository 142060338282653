@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.headingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > *:first-child {
        margin-bottom: var(--spacing-core-2);
    }
}

.cards {
    margin: var(--spacing-core-8) 0;
}

.carousel {
    scroll-padding-left: var(--spacing-core-4);
    scrollbar-width: none;
    margin-bottom: calc(var(--spacing-core-8) * -1);
}

.carouselItem {
    margin-bottom: var(--spacing-core-8);
    width: 300px;

    @include mq.mq($from: medium) {
        width: 350px;
    }

    &:first-child {
        margin-left: var(--spacing-core-4);
    }

    &:last-child {
        margin-right: var(--spacing-core-4);
    }
}

.cta {
    display: flex;
    justify-content: center;
    margin-top: 6px;
    margin-bottom: var(--spacing-core-8);
}
