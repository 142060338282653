.content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: var(--spacing-core-5);
    box-sizing: content-box;
    flex-grow: 1;
    overflow: hidden;
    height: 2.5lh;
}
