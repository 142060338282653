@use '@royalaholddelhaize/design-system-pantry-web/utils/mq.scss' as mq;

.root {
    padding-left: var(--spacing-core-4);
    padding-right: var(--spacing-core-4);

    @include mq.mq($from: medium) {
        padding-left: var(--spacing-core-8);
        padding-right: var(--spacing-core-8);
    }
}
