.imageWrapper {
    aspect-ratio: 21 / 10;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.grid {
    padding: var(--spacing-core-3) 0;
}
