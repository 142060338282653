.root {
    container-type: inline-size;
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: var(--color-foreground-primary-default);
    overflow: hidden;

    &:hover,
    &:focus-visible {
        cursor: pointer;

        img {
            transform: scale(1.03);
        }
    }
}
